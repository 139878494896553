@keyframes rotate {
  from {
    transform: rotate(360deg);
  }
  to {
    transform: rotate(0deg);
  }
}

html,
body {
  overflow-x: hidden;
}
body {
  width: 100%;
}

svg.spin {
  animation-name: rotate;
  animation-duration: 1s;
  animation-iteration-count: infinite;
  animation-timing-function: linear;
}

button.mirror {
  transform: rotateY(180deg);
}
